











































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import {
  USER_REQUEST,
  USER_UPDATE,
  USER_CREATE,
  USER_DELETE,
  USER_UPDATED,
  USER_LOADED,
} from "@/store/actions/user";
import {
  REGION_REQUEST,
  REGION_LOADED,
} from "@/store/actions/main";

@Component({})
export default class Users extends Vue {
  private users: User[] = [];
  private regions: any[] = [];
  private userCreateForm: any = {};
  private usersHeaders = [
    {
      text: "Аватар",
      value: "avatar_path",
      align: "start",
      sortable: false,
      width: "1%",
    },
    {
      text: "Логин",
      value: "username",
      align: "start",
      width: "10%",
    },
    /*
		{
			text: "Пароль",
			value: "password",
			align: "center",
		},
        */
    {
      text: "Регион",
      value: "region_id",
      align: "start",
      width: "5%",
    },
    {
      text: "ФИО",
      value: "fio",
      align: "start",
      width: "15%",
    },
    {
      text: "Министерство",
      value: "ministry",
      align: "start",
      width: "15%",
    },
    {
      text: "Департамент",
      value: "department",
      align: "start",
      width: "15%",
    },
    {
      text: "Должность",
      value: "position",
      align: "start",
      width: "15%",
    },
    {
      text: "Телефон",
      value: "phone",
      align: "start",
      width: "10%",
    },
    {
      text: "Роль",
      value: "role",
      align: "start",
      width: "5%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private isAdmin: boolean = false;
  private loading: boolean = false;
  private search = "";
  private itemToDelete: User = new User();
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  private fullscreen = false;
  private imageView = false;
  private imgUrl = "";
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.USER_TABLE,
        this.$store.getters.USER_LOADED
      )
    );
    needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;

    if (
      this.needReload(
        this.$store.getters.USER_TABLE,
        this.$store.getters.USER_LOADED
      )
    ) {
      this.$store.dispatch(USER_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.users = this.$store.getters.USER_TABLE.data;
    this.regions = this.$store.getters.REGION_TABLE.data;
    if (localStorage.getItem("role") == "SU") {
      this.isAdmin = true;
    } else {
      this.usersHeaders.splice(this.usersHeaders.length - 1, 1);
    }
    this.loading = false;
    console.log("loading");
  }
  private getRegion(id){
    for(let i = 0; i < this.regions.length; i++){
      if(id == this.regions[i].id)
        return this.regions[i].title
    }
  }
  private toggle() {
    this.fullscreen = !this.fullscreen;
  }
  private getSrc(address) {
    return process.env.VUE_APP_API_ENDPOINT + "/" + address;
  }
  //Methods
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new User();
  }
  private toCreate() {
    //localStorage.removeItem("userToEdit");
    this.$router.push({ name: "UsersCreate" });
  }
  private toEdit(item) {
    console.log("item: ", item);
    const parsed = JSON.stringify(item);
    localStorage.setItem("userToEdit", parsed);
    this.$router.push({ name: "UsersUpdate", params: { id: item.id } });
  }
  private deleteItem(item: User, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      this.$store.dispatch(USER_DELETE, item.id).then(() => {
        this.tryToInitSelf();
        this.deleteSnackbar = true;
        this.itemToDelete = new User();
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
  private setImgUrl(url) {
    this.imageView = true;
    this.imgUrl = url;
  }
  //Hooks
  private mounted() {
    this.tryToInitSelf();
  }
  private nameSearch(value, search, item): boolean {
    return (
      value != null &&
      search != null &&
      typeof value === "string" &&
      value
        .toString()
        .toLocaleLowerCase()
        .indexOf(search.toString().toLocaleLowerCase()) !== -1
    );
  }
}
