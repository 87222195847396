export class User {
	constructor(
		public id?: number,
		public username?: string,
		public password?: string,
		public region_id?: number,
		public fio?: string,
		public ministry?: string,
		public department?: string,
		public position?: string,
		public phone?: string,
		public email?: string,
		public role?: string,
		public avatar_file?: any,
		public avatar_path?: any
	) {
		this.username = "";
		this.password = "";
		this.region_id = 0;
		this.fio = "";
		this.ministry = "";
		this.department = "";
		this.position = "";
		this.phone = "";
		this.email = "";
		this.role = "";
		this.avatar_file = "";
		this.avatar_path = "";
		//
		this.username = username;
		this.password = password;
		this.region_id = 0;
		this.fio = fio;
		this.ministry = ministry;
		this.department = department;
		this.position = position;
		this.phone = phone;
		this.email = email;
		this.role = role;
		this.avatar_file = avatar_file;
		this.avatar_path = avatar_path;
	}
}
